// src/pages/SeasonTicketPage.js
import React, { useState, useEffect } from 'react';
import { Container, Card, Button, Modal, Form, Row, Col } from 'react-bootstrap';
import { db, auth } from '../firebase';
import { collection, addDoc, getDocs, getDoc, query, where, onSnapshot, serverTimestamp, doc } from 'firebase/firestore';
import { onAuthStateChanged } from 'firebase/auth';
import '../styles/SeasonTicketPage.css';

function SeasonTicketPage() {
  const [availableTickets, setAvailableTickets] = useState({});
  const [showAddTicketModal, setShowAddTicketModal] = useState(false);
  const [showSaveSeasonTicketModal, setShowSaveSeasonTicketModal] = useState(false);
  const [games, setGames] = useState([]);
  const [selectedGame, setSelectedGame] = useState('');
  const [user, setUser] = useState(null);
  const [isSTH, setIsSTH] = useState(false);
  const [seasonTicketSaved, setSeasonTicketSaved] = useState(false);
  const [block, setBlock] = useState('');
  const [row, setRow] = useState('');
  const [seat, setSeat] = useState('');

  useEffect(() => {
    const unsubscribe = onAuthStateChanged(auth, (currentUser) => {
      setUser(currentUser);
      if (currentUser) {
        checkUserRole(currentUser.uid);
        checkSeasonTicketDetails(currentUser.uid);
      }
    });
    return () => unsubscribe();
  }, []);

  useEffect(() => {
    fetchAvailableTickets();
    fetchGames();
  }, []);

  const checkUserRole = async (userId) => {
    const userDoc = await getDocs(query(collection(db, 'members'), where('uid', '==', userId)));
    if (!userDoc.empty) {
      const userData = userDoc.docs[0].data();
      setIsSTH(userData.role === 'season ticket holder' || userData.role === 'admin');
    }
  };

  const checkSeasonTicketDetails = async (userId) => {
    const ticketDoc = await getDocs(query(collection(db, 'seasonTickets'), where('userID', '==', userId)));
    setSeasonTicketSaved(!ticketDoc.empty);
  };

  const fetchAvailableTickets = () => {
    const q = query(collection(db, 'STAvailable'));
    onSnapshot(q, async (snapshot) => {
      const tickets = await Promise.all(snapshot.docs.map(async (ticketDoc) => {
        const ticketData = ticketDoc.data();
        const userSnapshot = await getDoc(doc(db, 'members', ticketData.userID));
        const matchSnapshot = await getDoc(doc(db, 'matches', ticketData.matchID));
        const seasonTicketSnapshot = await getDocs(query(collection(db, 'seasonTickets'), where('userID', '==', ticketData.userID)));

        let ticketDetails = {};
        if (!seasonTicketSnapshot.empty) {
          const seasonTicketData = seasonTicketSnapshot.docs[0].data();
          ticketDetails = {
            block: seasonTicketData.block,
            row: seasonTicketData.row,
            seat: seasonTicketData.seat,
          };
        }

        return {
          id: ticketDoc.id,
          ...ticketData,
          userName: userSnapshot.exists() ? `${userSnapshot.data().FName} ${userSnapshot.data().LName}` : 'Unknown User',
          matchName: matchSnapshot.exists() ? matchSnapshot.data().name : 'Unknown Match',
          block: ticketDetails.block || 'N/A',
          row: ticketDetails.row || 'N/A',
          seat: ticketDetails.seat || 'N/A',
        };
      }));

      // Group tickets by match
      const groupedTickets = tickets.reduce((groups, ticket) => {
        if (!groups[ticket.matchName]) {
          groups[ticket.matchName] = [];
        }
        groups[ticket.matchName].push(ticket);
        return groups;
      }, {});
      setAvailableTickets(groupedTickets);
    });
  };

  const fetchGames = async () => {
    const gamesSnapshot = await getDocs(collection(db, 'matches'));
    const gamesList = gamesSnapshot.docs.map(doc => ({ id: doc.id, ...doc.data() }));
    setGames(gamesList);
  };

  const handleAddTicket = async () => {
    if (selectedGame) {
      try {
        await addDoc(collection(db, 'STAvailable'), {
          userID: user.uid,
          matchID: selectedGame,
          createdAt: serverTimestamp(),
        });
        setShowAddTicketModal(false);
        setSelectedGame('');
      } catch (error) {
        console.error('Error adding ticket:', error);
      }
    }
  };

  const handleSaveSeasonTicketDetails = () => {
    setShowSaveSeasonTicketModal(true);
  };

  const handleConfirmSaveSeasonTicketDetails = async () => {
    try {
      await addDoc(collection(db, 'seasonTickets'), {
        userID: user.uid,
        block,
        row,
        seat,
        createdAt: serverTimestamp(),
      });
      setSeasonTicketSaved(true);
      setShowSaveSeasonTicketModal(false);
    } catch (error) {
      console.error('Error saving season ticket details:', error);
    }
  };

  return (
    <Container className="mt-4">
      <h2 className="text-center mb-4">Season Ticket Holder Tickets</h2>
      {Object.keys(availableTickets).length > 0 ? (
        Object.keys(availableTickets).map((matchName) => (
          <Card key={matchName} className="mb-3">
            <Card.Header>{matchName}</Card.Header>
            <Card.Body>
              <Row>
                {availableTickets[matchName].map((ticket, index) => (
                  <Col key={ticket.id} md={4} className="mb-4">
                    <Card>
                      <Card.Body>
                        <Card.Title>User: {ticket.userName}</Card.Title>
                        <Card.Text>
                          <strong>Block:</strong> {ticket.block} <br />
                          <strong>Row:</strong> {ticket.row} <br />
                          <strong>Seat:</strong> {ticket.seat}
                        </Card.Text>
                      </Card.Body>
                    </Card>
                  </Col>
                ))}
              </Row>
            </Card.Body>
          </Card>
        ))
      ) : (
        <p>No tickets available at the moment.</p>
      )}

      {isSTH && (
        <>
          <Button variant="primary" onClick={() => setShowAddTicketModal(true)} className="mt-3">
            Add Tickets
          </Button>
          {!seasonTicketSaved && (
            <Button variant="secondary" onClick={handleSaveSeasonTicketDetails} className="mt-3 ms-2">
              Save Season Ticket Details
            </Button>
          )}
        </>
      )}

      {/* Add Ticket Modal */}
      <Modal show={showAddTicketModal} onHide={() => setShowAddTicketModal(false)}>
        <Modal.Header closeButton>
          <Modal.Title>Add Ticket Availability</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form>
            <Form.Group controlId="formGameSelect" className="mb-3">
              <Form.Label>Select Game</Form.Label>
              <Form.Select value={selectedGame} onChange={(e) => setSelectedGame(e.target.value)}>
                <option value="">Select a game...</option>
                {games.map((game) => (
                  <option key={game.id} value={game.id}>{game.name}</option>
                ))}
              </Form.Select>
            </Form.Group>
          </Form>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={() => setShowAddTicketModal(false)}>
            Close
          </Button>
          <Button variant="primary" onClick={handleAddTicket} disabled={!selectedGame}>
            Save Ticket
          </Button>
        </Modal.Footer>
      </Modal>

      {/* Save Season Ticket Modal */}
      <Modal show={showSaveSeasonTicketModal} onHide={() => setShowSaveSeasonTicketModal(false)}>
        <Modal.Header closeButton>
          <Modal.Title>Save Season Ticket Details</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form>
            <Form.Group controlId="formBlock" className="mb-3">
              <Form.Label>Block</Form.Label>
              <Form.Control
                type="text"
                value={block}
                onChange={(e) => setBlock(e.target.value)}
                placeholder="Enter block number"
              />
            </Form.Group>
            <Form.Group controlId="formRow" className="mb-3">
              <Form.Label>Row</Form.Label>
              <Form.Control
                type="text"
                value={row}
                onChange={(e) => setRow(e.target.value)}
                placeholder="Enter row number"
              />
            </Form.Group>
            <Form.Group controlId="formSeat" className="mb-3">
              <Form.Label>Seat</Form.Label>
              <Form.Control
                type="text"
                value={seat}
                onChange={(e) => setSeat(e.target.value)}
                placeholder="Enter seat number"
              />
            </Form.Group>
          </Form>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={() => setShowSaveSeasonTicketModal(false)}>
            Close
          </Button>
          <Button variant="primary" onClick={handleConfirmSaveSeasonTicketDetails}>
            Save Details
          </Button>
        </Modal.Footer>
      </Modal>
    </Container>
  );
}

export default SeasonTicketPage;
