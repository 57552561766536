import React, { useState } from 'react';
import { Container, Row, Col, Button, Form, Alert } from 'react-bootstrap';
import { addDoc, collection } from 'firebase/firestore';
import { db } from '../firebase'; // Import Firestore instance
import '../styles/HomePage.css'; // Importing custom styles for the HomePage
import axios from 'axios';

function HomePage() {
  const [name, setName] = useState('');
  const [email, setEmail] = useState('');
  const [message, setMessage] = useState('');
  const [isSubmitting, setIsSubmitting] = useState(false);

const handleSubmit = async (e) => {
  e.preventDefault();
  setMessage('');
  setIsSubmitting(true);

  try {
    // Make a request to the Cloud Function
    const response = await axios.post(
      'https://us-central1-corkmusc-f2191.cloudfunctions.net/sendInterestEmail', 
      { name, email }
    );

    if (response.status === 200) {
      setMessage('Thank you! We will send you more information about joining the club soon.');
    } else {
      setMessage('An error occurred while submitting your details. Please try again.');
    }

    setName('');
    setEmail('');
  } catch (error) {
    console.error('Error sending interest email:', error);
    setMessage('An error occurred while submitting your details. Please try again.');
  } finally {
    setIsSubmitting(false);
  }
};

  return (
    <div>
      {/* Existing Content */}
      <div className="top-image-container">
        <img
          className="top-image"
          src={require('../images/carousel/Old-Trafford-Manchester-United.jpg')}
          alt="CorkMUSC Banner"
        />
      </div>

      {/* Information Sections About the Club */}
      <Container className="mt-5">
        {/* Section 1: About the Club */}
        <Row className="mb-5">
          <Col md={6}>
            <img
              src={require('../images/RedMUSC.jpg')}
              alt="About the club"
              className="img-fluid rounded imgMUSC"
            />
          </Col>
          <Col md={6}>
            <h2>About Cork MUSC</h2>
            <p>
              Cork Manchester United Supporters Club (Cork MUSC) is the official supporters club for Manchester United fans in Cork.
              We bring together passionate supporters from all over the region to celebrate the love for the Reds.
              Established as an official supporters club, we have strived to promote the team in every way we can.
            </p>
          </Col>
        </Row>

        {/* Section 2: Events and Activities */}
        <Row className="mb-5">
          <Col md={6}>
            <h2>Events and Activities</h2>
            <p>
              We organize match screenings, social events, and community activities to keep our members engaged and connected. 
              Join us for our upcoming events and experience the passion and excitement with fellow supporters. The official bar for Cork MUSC is The Blackpool Tavern.
            </p>
            <Button
              variant="primary"
              href="https://maps.app.goo.gl/KgFmuCc3puEFfRaC6"
              target="_blank"
              rel="noopener noreferrer"
              className="mt-3"
            >
              View on Google Maps
            </Button>
          </Col>
          <Col md={6}>
            <img
              src={require('../images/WhiteMUSC.jpg')}
              alt="Events and activities"
              className="img-fluid rounded imgMUSC"
            />
          </Col>
        </Row>

        {/* Section 3: Membership Benefits */}
        <Row className="mb-5">
        <Col md={6}>
            <img
              src={require('../images/blackMUSC.jpg')}
              alt="Membership benefits"
              className="img-fluid rounded imgMUSC"
            />
          </Col>
          <Col md={6}>
            <h2>Membership Benefits</h2>
            <p>
              Becoming a Cork MUSC member gives you exclusive access to match tickets, club merchandise, and special events. Get closer to the action and enjoy the perks of being part of a vibrant football fan community.
            </p>
          </Col>

        </Row>

        {/* Form Section: Interest in Joining the Club */}
        <Row className="mb-5">
          <Col>
            <h2>Interested in Joining the Club?</h2>
            <p>Fill in your details below and we will send you more information on how to join Cork MUSC.</p>
            <Form onSubmit={handleSubmit}>
              <Form.Group controlId="formName" className="mb-3">
                <Form.Label>Full Name</Form.Label>
                <Form.Control
                  type="text"
                  placeholder="Enter your full name"
                  value={name}
                  onChange={(e) => setName(e.target.value)}
                  required
                />
              </Form.Group>

              <Form.Group controlId="formEmail" className="mb-3">
                <Form.Label>Email Address</Form.Label>
                <Form.Control
                  type="email"
                  placeholder="Enter your email"
                  value={email}
                  onChange={(e) => setEmail(e.target.value)}
                  required
                />
              </Form.Group>

              <Button variant="primary" type="submit" disabled={isSubmitting}>
                {isSubmitting ? 'Submitting...' : 'Submit'}
              </Button>
            </Form>

            {message && (
              <Alert variant={message.startsWith('Thank') ? 'success' : 'danger'} className="mt-3">
                {message}
              </Alert>
            )}
          </Col>
        </Row>
      </Container>
    </div>
  );
}

export default HomePage;
