// src/components/Footer.js
import React from 'react';
import { Container, Row, Col } from 'react-bootstrap';
import { FaFacebookF, FaTwitter } from 'react-icons/fa'; // Importing Facebook and Twitter icons from React Icons
import '../styles/Footer.css';

const Footer = () => {
  return (
    <footer className="footer-container">
      <Container fluid className="p-0">
        <Row className="footer-row no-gutters">
          {/* Section 1: Address */}
          <Col md={4} className="footer-section footer-red d-flex flex-column justify-content-center align-items-center">
            <h5>Our Location</h5>
            <p><strong>The Blackpool Tavern</strong></p>
            <p>14 Dublin St, Blackpool, Cork, T23 YP58</p>
            <p>Join us on match days</p>
          </Col>

          {/* Section 2: Contact Info */}
          <Col md={4} className="footer-section footer-white d-flex flex-column justify-content-center align-items-center">
            <h5>Contact Us</h5>
            <p>Email: <a href="mailto:corkmusc@gmail.com" className="footer-link">corkmusc@gmail.com</a></p>
            <div>
              <a href="https://www.facebook.com/groups/930555397365246/" target="_blank" rel="noopener noreferrer" className="footer-link me-3">
                <FaFacebookF size={20} /> Facebook
              </a>
              {/*<a href="#" target="_blank" rel="noopener noreferrer" className="footer-link">
                <FaTwitter size={20} /> X (link coming soon)
              </a>*/}
            </div>
          </Col>

          {/* Section 3: Additional Information */}
          <Col md={4} className="footer-section footer-black d-flex flex-column justify-content-center align-items-center">
            <h5>More About Us</h5>
            <p>Join our community and stay updated on upcoming events, match schedules, and exclusive club news.</p>
          </Col>
        </Row>
      </Container>
    </footer>
  );
};

export default Footer;
