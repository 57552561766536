// src/services/firebaseServices.js
import { db } from '../firebase';
import { collection, getDocs, addDoc, deleteDoc, doc, query, where, getDoc, updateDoc, Timestamp, limit, arrayUnion } from 'firebase/firestore';
import moment from 'moment';

/**
 * Fetch all matches from Firestore
 */
export const fetchMatches = async () => {
  try {
    const matchesCollection = collection(db, 'matches');
    const matchSnapshot = await getDocs(matchesCollection);
    return matchSnapshot.docs.map((doc) => ({
      id: doc.id,
      ...doc.data(),
    }));
  } catch (error) {
    console.error("Error fetching matches: ", error);
    throw error;
  }
};

/**
 * Add a new match to Firestore
 * @param {Object} newMatch 
 */
export const addMatch = async (newMatch) => {
  try {
    await addDoc(collection(db, 'matches'), newMatch);
  } catch (error) {
    console.error("Error adding new match: ", error);
    throw error;
  }
};

/**
 * Delete a match from Firestore
 * @param {string} matchId 
 */
export const deleteMatch = async (matchId) => {
  try {
    await deleteDoc(doc(db, 'matches', matchId));
  } catch (error) {
    console.error("Error deleting match: ", error);
    throw error;
  }
};

/**
 * Fetch user details from Firestore, including linked accounts
 * @param {string} userId 
 */
export const fetchUserDetails = async (userId) => {
  try {
    const userDoc = await getDoc(doc(db, 'members', userId));
    if (userDoc.exists()) {
      const userData = userDoc.data();

      // Fetch linked accounts if they exist
      if (userData.linkedAccounts && userData.linkedAccounts.length > 0) {
        const linkedAccountsData = await Promise.all(
          userData.linkedAccounts.map(async (linkedId) => {
            const linkedDoc = await getDoc(doc(db, 'members', linkedId));
            return linkedDoc.exists() ? { id: linkedId, ...linkedDoc.data() } : null;
          })
        );
        // Filter out any null values in case some linked accounts do not exist
        userData.linkedAccounts = linkedAccountsData.filter(account => account !== null);
      } else {
        userData.linkedAccounts = [];
      }

      return userData;
    } else {
      return null;
    }
  } catch (error) {
    console.error("Error fetching user details: ", error);
    throw error;
  }
};

/**
 * Fetch applications for a given user from Firestore
 * @param {string} userId 
 */
export const fetchApplicationsForUser = async (userIds) => {
  try {
    // Ensure `userIds` is an array. If it's a single user ID, convert it to an array
    if (!Array.isArray(userIds)) {
      userIds = [userIds];
    }

    const applicationsRef = collection(db, 'applications');

    // Query applications for all provided user IDs using the `in` operator
    const applicationsQuery = query(applicationsRef, where('userID', 'in', userIds));
    const querySnapshot = await getDocs(applicationsQuery);

    // Map and return applications data
    return querySnapshot.docs.map((doc) => ({
      id: doc.id,
      ...doc.data(),
    }));
  } catch (error) {
    console.error("Error fetching applications for users: ", error);
    return [];
  }
};



export const fetchApplicationsForMatch = async (matchId) => {
    try {
      const applicationsRef = collection(db, 'applications');
      const applicationsQuery = query(applicationsRef, where('matchID', '==', matchId));
      const applicationsSnapshot = await getDocs(applicationsQuery);
  
      const applicationsData = applicationsSnapshot.docs.map((doc) => {
        const data = doc.data();
        return {
          ...data,
          id: doc.id,
          dateOfBirth: data.dateOfBirth ? moment(data.dateOfBirth.toDate()) : null,
        };
      });
  
      return applicationsData;
    } catch (error) {
      console.error("Error fetching applications for match: ", error);
      throw new Error("Unable to fetch applications for match");
    }
  };

/**
 * Add a new application
 * @param {Object} application 
 */
export const addApplication = async (application) => {
  try {
    await addDoc(collection(db, 'applications'), application);
  } catch (error) {
    console.error("Error adding application: ", error);
    throw error;
  }
};

/**
 * Delete an application from Firestore
 * @param {string} applicationId 
 */
export const deleteApplication = async (applicationId) => {
  try {
    await deleteDoc(doc(db, 'applications', applicationId));
  } catch (error) {
    console.error("Error deleting application: ", error);
    throw error;
  }
};

/**
 * Remove a linked account from both users' linkedMemberID list
 * @param {string} userId
 * @param {string} linkedAccountId 
 */
export const removeLinkedAccountReference = async (userId, linkedAccountId) => {
  try {
    // Reference to the current user document
    const userDocRef = doc(db, 'members', userId);
    const linkedUserDocRef = doc(db, 'members', linkedAccountId);

    // Fetch the current user details
    const userDoc = await getDoc(userDocRef);
    const linkedUserDoc = await getDoc(linkedUserDocRef);

    if (userDoc.exists()) {
      const userData = userDoc.data();

      // Ensure linkedMemberID is a valid array before trying to filter
      if (Array.isArray(userData.linkedMemberID)) {
        const updatedLinkedAccounts = userData.linkedMemberID.filter(id => id !== linkedAccountId);

        // Update Firestore document with the modified array for current user
        await updateDoc(userDocRef, {
          linkedMemberID: updatedLinkedAccounts
        });
      } else {
        console.error("linkedMemberID is not an array for current user.");
      }
    } else {
      console.error("User not found.");
    }

    // Fetch and update the linked user's details to remove the reference to the current user
    if (linkedUserDoc.exists()) {
      const linkedUserData = linkedUserDoc.data();

      // Ensure linkedMemberID is a valid array before trying to filter
      if (Array.isArray(linkedUserData.linkedMemberID)) {
        const updatedLinkedAccounts = linkedUserData.linkedMemberID.filter(id => id !== userId);

        // Update Firestore document with the modified array for linked user
        await updateDoc(linkedUserDocRef, {
          linkedMemberID: updatedLinkedAccounts
        });
      } else {
        console.error("linkedMemberID is not an array for linked user.");
      }
    } else {
      console.error("Linked user not found.");
    }

    console.log("Linked account reference removed successfully from both users.");
  } catch (error) {
    console.error("Error removing linked account reference: ", error);
    throw error;
  }
};

/**
 * Search for users in Firestore based on name or membership number
 * @param {string} searchTerm 
 */
export const searchUsers = async (searchTerm) => {
  try {
    const membersRef = collection(db, 'members');
    const results = new Map(); // To hold unique results

    // Firestore cannot do a combined `or` on different fields in a single query, 
    // so we need to do them separately.

    // Query for users by name (first or last)
    const nameQuery = query(
      membersRef,
      where('FName', '>=', searchTerm),
      where('FName', '<=', searchTerm + '\uf8ff'),
      limit(10)
    );
    const nameSnapshot = await getDocs(nameQuery);
    nameSnapshot.forEach(doc => {
      results.set(doc.id, { id: doc.id, ...doc.data() });
    });

    // Query for users by membership number
    const membershipQuery = query(
      membersRef,
      where('membershipNumber', '==', searchTerm),
      limit(10)
    );
    const membershipSnapshot = await getDocs(membershipQuery);
    membershipSnapshot.forEach(doc => {
      results.set(doc.id, { id: doc.id, ...doc.data() });
    });

    // Convert results map to an array and return
    return Array.from(results.values());
  } catch (error) {
    console.error("Error searching users: ", error);
    throw error;
  }
};

/**
 * Send a link request to another user
 * @param {string} requesterID 
 * @param {string} requesteeID 
 */
export const sendLinkRequest = async (requesterID, requesteeID) => {
  try {
    const linkRequestsRef = collection(db, 'linkRequests');
    await addDoc(linkRequestsRef, {
      createdAt: Timestamp.now(),
      requesterID,
      requesteeID,
      status: 'pending'
    });
    console.log("Link request sent successfully.");
  } catch (error) {
    console.error("Error sending link request: ", error);
    throw error;
  }
};


/**
 * Fetch pending link requests for a given user
 * @param {string} userId 
 */
export const fetchPendingLinkRequests = async (userId) => {
  try {
    const linkRequestsCollection = collection(db, 'linkRequests');
    const pendingRequestsQuery = query(
      linkRequestsCollection,
      where('requesteeID', '==', userId),
      where('status', '==', 'pending')
    );
    const querySnapshot = await getDocs(pendingRequestsQuery);

    // Fetch additional details for the requester
    const pendingRequests = await Promise.all(
      querySnapshot.docs.map(async (requestDoc) => {
        const requestData = requestDoc.data();

        // Fetch the requester details from Firestore
        const requesterDoc = await getDoc(doc(db, 'members', requestData.requesterID));

        let requesterData = {};
        if (requesterDoc.exists()) {
          requesterData = requesterDoc.data();
        }

        return {
          id: requestDoc.id,
          ...requestData,
          requesterName: requesterData.FName + ' ' + requesterData.LName,
          requesterMembershipNumber: requesterData.membershipNumber,
        };
      })
    );

    return pendingRequests;
  } catch (error) {
    console.error("Error fetching pending link requests: ", error);
    throw error;
  }
};

/**
 * Update the status of a link request
 * @param {string} requestID 
 * @param {string} status 
 */
export const updateLinkRequestStatus = async (requestID, status) => {
  try {
    const requestRef = doc(db, 'linkRequests', requestID);
    await updateDoc(requestRef, { status });
  } catch (error) {
    console.error("Error updating link request status: ", error);
    throw error;
  }
};

/**
 * Add linked account reference for both users
 * @param {string} userId
 * @param {string} linkedAccountId
 */
export const addLinkedAccount = async (userId, linkedAccountId) => {
  try {
    const userDocRef = doc(db, 'members', userId);
    await updateDoc(userDocRef, {
      linkedMemberID: arrayUnion(linkedAccountId)
    });
  } catch (error) {
    console.error("Error adding linked account: ", error);
    throw error;
  }
};

/**
 * Fetch users by role from Firestore
 * @param {string} role 
 */
export const fetchUsersByRole = async (role) => {
  try {
    const membersRef = collection(db, 'members');
    const roleQuery = query(membersRef, where('role', '==', role));
    const querySnapshot = await getDocs(roleQuery);
    console.log("Query snapshot size:", querySnapshot.size);
    return querySnapshot.docs.map((doc) => ({ id: doc.id, ...doc.data() }));
  } catch (error) {
    console.error("Error fetching users by role: ", error);
    throw error;
  }
};

/**
 * Update user details in Firestore
 * @param {string} userId 
 * @param {Object} updatedData 
 */
export const updateUserDetails = async (userId, updatedData) => {
  try {
    const userDocRef = doc(db, 'members', userId);
    await updateDoc(userDocRef, updatedData);
  } catch (error) {
    console.error("Error updating user details: ", error);
    throw error;
  }
};

/**
 * Update match details in Firestore
 * @param {string} matchId - The ID of the match to update
 * @param {Object} updatedMatchDetails - An object containing the updated details of the match
 */
export const updateMatchDetails = async (matchId, updatedMatchDetails) => {
  try {
    const matchDocRef = doc(db, 'matches', matchId);

    // Adjust timestamp fields if necessary
    const fieldsToUpdate = { ...updatedMatchDetails };

    if (updatedMatchDetails.matchDate) {
      fieldsToUpdate.matchDate = Timestamp.fromDate(new Date(updatedMatchDetails.matchDate));
    }
    if (updatedMatchDetails.applicationOpenDate) {
      fieldsToUpdate.applicationOpenDate = Timestamp.fromDate(new Date(updatedMatchDetails.applicationOpenDate));
    }
    if (updatedMatchDetails.applicationCloseDate) {
      fieldsToUpdate.applicationCloseDate = Timestamp.fromDate(new Date(updatedMatchDetails.applicationCloseDate));
    }

    // Update the match document in Firestore
    await updateDoc(matchDocRef, fieldsToUpdate);
    console.log("Match details updated successfully.");
  } catch (error) {
    console.error("Error updating match details: ", error);
    throw error;
  }
};

/**
 * Fetch all users from Firestore
 */
export const fetchAllUsers = async () => {
  try {
    const usersCollection = collection(db, 'members'); // Assuming your users are stored in the 'members' collection
    const usersSnapshot = await getDocs(usersCollection);
    
    return usersSnapshot.docs.map((doc) => ({
      id: doc.id,
      ...doc.data(),
    }));
  } catch (error) {
    console.error("Error fetching all users: ", error);
    throw error;
  }
};

// Fetch applications by user
export async function fetchApplicationsByUser(userId) {
  const q = query(collection(db, 'applications'), where('userId', '==', userId));
  const querySnapshot = await getDocs(q);
  return querySnapshot.docs.map(doc => ({ id: doc.id, ...doc.data() }));
}

/**
 * Fetch details of a single match from Firestore
 * @param {string} matchId - The ID of the match to fetch details for
 */
export const fetchMatchDetails = async (matchId) => {
  try {
    // Use the v9 syntax for getting a document reference
    const matchDocRef = doc(db, 'matches', matchId); // Create a document reference for the match
    const matchDoc = await getDoc(matchDocRef); // Fetch the document snapshot
    
    // Check if the document exists and return the data
    if (matchDoc.exists()) {
      return { id: matchDoc.id, ...matchDoc.data() }; // Return match details including the document ID
    } else {
      throw new Error('Match not found');
    }
  } catch (error) {
    console.error("Error fetching match details:", error);
    throw error; // Rethrow the error to be handled by the caller
  }
};

export const updateApplicationDetails = async (applicationId, updatedDetails) => {
  try {
    const applicationRef = doc(db, 'applications', applicationId);
    await updateDoc(applicationRef, updatedDetails);
    console.log('Application updated successfully');
  } catch (error) {
    console.error('Error updating application:', error);
  }
};

// Function to delete a user profile and remove their ID from linked accounts
export const deleteUser = async (userId) => {
  try {
    // Reference to the user document
    const userDocRef = doc(db, 'users', userId);

    // Delete the user document
    await deleteDoc(userDocRef);
    console.log(`User document with ID: ${userId} deleted successfully.`);

    // Find all linked accounts and remove references to the deleted user
    const linkedAccountsRef = collection(db, 'linkedAccounts');
    const linkedAccountsQuery = query(linkedAccountsRef, where('linkedUserIds', 'array-contains', userId));

    const linkedAccountsSnapshot = await getDocs(linkedAccountsQuery);
    linkedAccountsSnapshot.forEach(async (linkedAccountDoc) => {
      const linkedAccountData = linkedAccountDoc.data();
      const updatedLinkedUserIds = linkedAccountData.linkedUserIds.filter((id) => id !== userId);

      // Update the linked account to remove the deleted user ID
      const linkedAccountDocRef = doc(db, 'linkedAccounts', linkedAccountDoc.id);
      await updateDoc(linkedAccountDocRef, {
        linkedUserIds: updatedLinkedUserIds,
      });
      console.log(`User ID: ${userId} removed from linked account ID: ${linkedAccountDoc.id}`);
    });

    // Optionally delete user applications if needed
    const applicationsRef = collection(db, 'applications');
    const applicationsQuery = query(applicationsRef, where('userID', '==', userId));
    const applicationsSnapshot = await getDocs(applicationsQuery);
    applicationsSnapshot.forEach(async (applicationDoc) => {
      await deleteDoc(doc(db, 'applications', applicationDoc.id));
      console.log(`Application ID: ${applicationDoc.id} for user ID: ${userId} deleted successfully.`);
    });

  } catch (error) {
    console.error('Error deleting user:', error);
    throw new Error('Failed to delete user.');
  }
};