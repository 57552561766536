import React, { useState, useEffect } from 'react';
import { Container, Tabs, Tab, Card, Row, Col, Badge, Button, Form, Modal, InputGroup } from 'react-bootstrap';
import { auth, db } from '../firebase';
import { onAuthStateChanged } from 'firebase/auth';
import { collection, addDoc, doc, updateDoc, getDoc, query, where, getDocs, orderBy, startAt, endAt } from 'firebase/firestore';
import { FaTrashAlt } from 'react-icons/fa';
import '../styles/ProfilePage.css';

// Utility functions
const fetchUserDetails = async (uid) => {
  try {
    const userDoc = await getDoc(doc(db, 'members', uid));
    if (userDoc.exists()) {
      return { id: uid, ...userDoc.data() };
    }
    return null;
  } catch (error) {
    console.error("Error fetching user details: ", error);
    return null;
  }
};

const convertTimestampToDate = (timestamp) => {
  if (!timestamp) return '';

  let date;
  if (typeof timestamp === 'string') {
    // If timestamp is already a string (fallback)
    date = new Date(timestamp);
  } else if (timestamp.seconds) {
    // If timestamp is a Firestore Timestamp
    date = new Date(timestamp.seconds * 1000);
  } else {
    // If timestamp is a Date object or something else
    date = new Date(timestamp);
  }

  const day = String(date.getDate()).padStart(2, '0');
  const month = String(date.getMonth() + 1).padStart(2, '0'); // Months are zero-based
  const year = date.getFullYear();

  return `${day}/${month}/${year}`;
};


const fetchPendingLinkRequests = async (userId) => {
  try {
    const requestsRef = collection(db, 'linkRequests');
    const q = query(requestsRef, where("requesteeID", "==", userId), where("status", "==", "pending"));
    const querySnapshot = await getDocs(q);

    const pendingRequests = await Promise.all(querySnapshot.docs.map(async (requestDoc) => {
      const requestData = requestDoc.data();
      const requesterId = requestData.requesterID;

      let requesterData = {};
      try {
        const requesterDoc = await getDoc(doc(db, 'members', requesterId));
        if (requesterDoc.exists()) {
          requesterData = requesterDoc.data();
        }
      } catch (error) {
        console.error("Error fetching requester details: ", error);
      }

      return {
        id: requestDoc.id,
        ...requestData,
        FName: requesterData.FName || 'Unknown',
        LName: requesterData.LName || 'Unknown',
      };
    }));

    return pendingRequests;
  } catch (error) {
    console.error("Error fetching pending link requests: ", error);
    return [];
  }
};

const sendLinkRequest = async (requesterID, requesteeID) => {
  try {
    await addDoc(collection(db, 'linkRequests'), {
      requesterID,
      requesteeID,
      status: 'pending',
    });
  } catch (error) {
    console.error("Error sending link request: ", error);
  }
};

const updateLinkRequestStatus = async (requestID, status) => {
  try {
    await updateDoc(doc(db, 'linkRequests', requestID), { status });
  } catch (error) {
    console.error("Error updating link request status: ", error);
  }
};

const searchUsers = async (searchQuery) => {
  try {
    const membersRef = collection(db, 'members');
    const membershipQuery = query(membersRef, where("membershipNumber", "==", searchQuery));
    const nameQuery = query(membersRef, orderBy('FName'), startAt(searchQuery), endAt(searchQuery + '\uf8ff'));
    const [membershipSnapshot, nameSnapshot] = await Promise.all([getDocs(membershipQuery), getDocs(nameQuery)]);
    const resultsSet = new Set();
    membershipSnapshot.forEach((doc) => resultsSet.add({ id: doc.id, ...doc.data() }));
    nameSnapshot.forEach((doc) => resultsSet.add({ id: doc.id, ...doc.data() }));
    return Array.from(resultsSet);
  } catch (error) {
    console.error("Error searching users: ", error);
    return [];
  }
};

const removeLinkedAccountReference = async (userId, linkedAccountId) => {
  try {
    const userRef = doc(db, 'members', userId);
    const userDoc = await getDoc(userRef);
    if (userDoc.exists()) {
      const linkedMemberIDs = userDoc.data().linkedMemberID || [];
      const updatedLinkedMemberIDs = linkedMemberIDs.filter(id => id !== linkedAccountId);
      await updateDoc(userRef, { linkedMemberID: updatedLinkedMemberIDs });
    }
  } catch (error) {
    console.error("Error removing linked account reference: ", error);
  }
};

const addLinkedAccount = async (userId, linkedAccountId) => {
  try {
    const userRef = doc(db, 'members', userId);
    const userDoc = await getDoc(userRef);
    if (userDoc.exists()) {
      const linkedMemberIDs = userDoc.data().linkedMemberID || [];
      linkedMemberIDs.push(linkedAccountId);
      await updateDoc(userRef, { linkedMemberID: linkedMemberIDs });
    }
  } catch (error) {
    console.error("Error adding linked account: ", error);
  }
};

// Main ProfilePage Component
function ProfilePage() {
  const [userDetails, setUserDetails] = useState(null);
  const [linkedMembersDetails, setLinkedMembersDetails] = useState([]);
  const [under18LinkedAccounts, setUnder18LinkedAccounts] = useState([]);
  const [activeTab, setActiveTab] = useState('profileDetails');
  const [searchQuery, setSearchQuery] = useState('');
  const [searchResults, setSearchResults] = useState([]);
  const [pendingRequests, setPendingRequests] = useState([]);
  const [showUnder18Modal, setShowUnder18Modal] = useState(false);
  const [newUnder18Details, setNewUnder18Details] = useState({
    FName: '',
    LName: '',
    membershipNumber: '',
    membershipType: '',
    dob: '',
    role: 'temp'
  });

  useEffect(() => {
    const loadUserDetails = async (user) => {
      if (user) {
        try {
          const userData = await fetchUserDetails(user.uid);
          setUserDetails(userData);

          if (userData && userData.linkedMemberID && userData.linkedMemberID.length > 0) {
            const linkedMembers = await Promise.all(
              userData.linkedMemberID.map(async (linkedId) => {
                const linkedDoc = await fetchUserDetails(linkedId);
                return linkedDoc ? { id: linkedId, ...linkedDoc } : null;
              })
            );
            const filteredLinkedMembers = linkedMembers.filter(member => member !== null);
            setLinkedMembersDetails(filteredLinkedMembers);
            setUnder18LinkedAccounts(filteredLinkedMembers.filter(member => member.membershipType === 'under 16'));
          }

          const pendingLinkRequests = await fetchPendingLinkRequests(user.uid);
          setPendingRequests(pendingLinkRequests);

        } catch (error) {
          console.error("Error fetching user details: ", error);
        }
      }
    };

    const unsubscribe = onAuthStateChanged(auth, (user) => {
      loadUserDetails(user);
    });

    return () => unsubscribe();
  }, []);

  useEffect(() => {
    const timeoutId = setTimeout(async () => {
      if (searchQuery.trim() !== '') {
        try {
          const results = await searchUsers(searchQuery);
          setSearchResults(results);
        } catch (error) {
          console.error("Error searching users: ", error);
        }
      } else {
        setSearchResults([]);
      }
    }, 300);
    return () => clearTimeout(timeoutId);
  }, [searchQuery]);

  const handleDeleteLinkedAccount = async (linkedAccountId) => {
    try {
      if (userDetails) {
        await removeLinkedAccountReference(userDetails.uid, linkedAccountId);
        setLinkedMembersDetails((prevDetails) =>
          prevDetails.filter((member) => member.id !== linkedAccountId)
        );
        setUnder18LinkedAccounts((prev) =>
          prev.filter((member) => member.id !== linkedAccountId)
        );
        setUserDetails((prevDetails) => ({
          ...prevDetails,
          linkedMemberID: prevDetails.linkedMemberID.filter((id) => id !== linkedAccountId),
        }));
      }
    } catch (error) {
      console.error("Error deleting linked account: ", error);
    }
  };

  const handleShowUnder18Modal = () => setShowUnder18Modal(true);
  const handleCloseUnder18Modal = () => setShowUnder18Modal(false);

  const handleUnder18InputChange = (e) => {
    const { name, value } = e.target;
    setNewUnder18Details((prev) => ({
      ...prev,
      [name]: value,
    }));
  };

  const handleAddUnder18Member = async () => {
    try {
      const newMemberRef = await addDoc(collection(db, 'members'), {
        ...newUnder18Details,
        dob: new Date(newUnder18Details.dob),
        role: 'temp'
      });

      const newMemberID = newMemberRef.id;
      await addLinkedAccount(userDetails.uid, newMemberID);

      setLinkedMembersDetails((prev) => [
        ...prev,
        { ...newUnder18Details, id: newMemberID }
      ]);
      setUnder18LinkedAccounts((prev) => [
        ...prev,
        { ...newUnder18Details, id: newMemberID }
      ]);

      handleCloseUnder18Modal();
    } catch (error) {
      console.error("Error adding under-18 member: ", error);
    }
  };

  const handleSearch = async (e) => {
    e.preventDefault();
    if (searchQuery.trim() === '') return;

    try {
      const results = await searchUsers(searchQuery);
      setSearchResults(results);
    } catch (error) {
      console.error("Error searching users: ", error);
    }
  };

  const handleSendLinkRequest = async (requesteeID) => {
    try {
      const requesterID = userDetails?.uid;
      if (requesterID && requesteeID) {
        await sendLinkRequest(requesterID, requesteeID);
        alert("Link request sent successfully.");
      }
    } catch (error) {
      console.error("Error sending link request: ", error);
    }
  };

  const handleAcceptLinkRequest = async (request) => {
    try {
      await updateLinkRequestStatus(request.id, 'accepted');
      await addLinkedAccount(request.requesterID, request.requesteeID);
      await addLinkedAccount(request.requesteeID, request.requesterID);
      setPendingRequests((prevRequests) => prevRequests.filter((r) => r.id !== request.id));
      const userData = await fetchUserDetails(request.requesteeID);
      setUserDetails(userData);
    } catch (error) {
      console.error("Error accepting link request: ", error);
    }
  };

  const handleRejectLinkRequest = async (requestID) => {
    try {
      await updateLinkRequestStatus(requestID, 'rejected');
      setPendingRequests((prevRequests) => prevRequests.filter((r) => r.id !== requestID));
      alert("Link request rejected successfully.");
    } catch (error) {
      console.error("Error rejecting link request: ", error);
    }
  };

  const handleEmailPreferencesChange = async () => {
    if (userDetails) {
      const updatedMatchDistribution = !userDetails.matchDistribution;

      try {
        await updateDoc(doc(db, 'members', userDetails.uid), {
          matchDistribution: updatedMatchDistribution,
        });

        setUserDetails((prevDetails) => ({
          ...prevDetails,
          matchDistribution: updatedMatchDistribution,
        }));
      } catch (error) {
        console.error("Error updating email preferences: ", error);
      }
    }
  };

  return (
    <Container className="mt-5">
      <Tabs
        id="profile-tabs"
        activeKey={activeTab}
        onSelect={(k) => setActiveTab(k || 'profileDetails')}
        className="mb-4 custom-tabs"
      >
        {/* Profile Details Tab */}
        <Tab eventKey="profileDetails" title="Profile Details">
          <Row>
            <Col md={6} className="mb-4">
              <Card className="shadow-sm">
                <Card.Header className="text-center">
                  <h4>Profile Details</h4>
                </Card.Header>
                <Card.Body>
                  {userDetails ? (
                    <>
                      <p><strong>Name:</strong> {userDetails.FName} {userDetails.LName}</p>
                      <p><strong>Membership Number:</strong> {userDetails.membershipNumber}</p>
                      <p><strong>Email:</strong> {userDetails.email}</p>
                      <p><strong>Date of Birth:</strong> {convertTimestampToDate(userDetails.dob)}</p>
                      <div className="mt-3">
                        <strong>Cork MUSC Status:</strong>
                        <Badge
                          pill
                          className={`ms-2 ${userDetails.registered === 'yes' ? 'bg-success' : 'bg-danger'}`}
                        >
                          {userDetails.registered === 'yes' ? 'Active' : 'Not Active'}
                        </Badge>
                      </div>
                      <div className="mt-3">
                        <strong>MUSC Paid:</strong>
                        <Badge
                          pill
                          className={`ms-2 ${userDetails.muscPaid === 'yes' ? 'bg-success' : 'bg-danger'}`}
                        >
                          {userDetails.muscPaid === 'yes' ? 'Active' : 'Not Active'}
                        </Badge>
                      </div>
                      <div className="mt-3">
                        <Form.Check
                          type="checkbox"
                          label="Email Preferences - Receive match-related notifications"
                          checked={userDetails.matchDistribution || false}
                          onChange={handleEmailPreferencesChange}
                        />
                      </div>
                    </>
                  ) : (
                    <p>Loading user details...</p>
                  )}
                </Card.Body>
              </Card>
            </Col>
            <Col md={6} className="mb-4">
              <Card className="shadow-sm">
                <Card.Header className="text-center">
                  <h4>Linked Accounts</h4>
                </Card.Header>
                <Card.Body>
                  {linkedMembersDetails && linkedMembersDetails.length > 0 ? (
                    <ul className="list-group">
                      {linkedMembersDetails.map((member) => (
                        <li key={member.id} className="list-group-item d-flex justify-content-between align-items-center">
                          {member.FName} {member.LName} (Membership: {member.membershipNumber})
                          <Button variant="outline-danger" size="sm" onClick={() => handleDeleteLinkedAccount(member.id)}>
                            <FaTrashAlt />
                          </Button>
                        </li>
                      ))}
                    </ul>
                  ) : (
                    <p>No linked accounts.</p>
                  )}
                </Card.Body>
              </Card>
            </Col>
          </Row>
          <Row>
            <Col md={6} className="mb-4">
              <Card className="shadow-sm">
                <Card.Header className="text-center">
                  <h4>Under 18 Linked Accounts</h4>
                </Card.Header>
                <Card.Body>
                  {under18LinkedAccounts && under18LinkedAccounts.length > 0 ? (
                    <ul className="list-group">
                      {under18LinkedAccounts.map((member) => (
                        <li key={member.id} className="list-group-item d-flex justify-content-between align-items-center">
                          {member.FName} {member.LName} (Membership: {member.membershipNumber})
                          <Button variant="outline-danger" size="sm" onClick={() => handleDeleteLinkedAccount(member.id)}>
                            <FaTrashAlt />
                          </Button>
                        </li>
                      ))}
                    </ul>
                  ) : (
                    <p>No under 18 linked accounts.</p>
                  )}
                  <Button className="mt-3" onClick={handleShowUnder18Modal}>Add Under 18 Member</Button>
                </Card.Body>
              </Card>
            </Col>
          </Row>
        </Tab>

        {/* Link Requests Tab */}
        <Tab eventKey="linkRequests" title="Link Requests">
          <Card className="shadow-sm mb-4">
            <Card.Header className="text-center">
              <h4>Send Link Request</h4>
            </Card.Header>
            <Card.Body>
              <Form onSubmit={handleSearch} className="mb-3">
                <InputGroup>
                  <Form.Control
                    type="text"
                    placeholder="Search by name or membership number"
                    value={searchQuery}
                    onChange={(e) => setSearchQuery(e.target.value)}
                  />
                  <Button type="submit" variant="primary">Search</Button>
                </InputGroup>
              </Form>
              {searchResults.length > 0 ? (
                <ul className="list-group">
                  {searchResults.map((result) => (
                    <li key={result.id} className="list-group-item d-flex justify-content-between align-items-center">
                      {result.FName} {result.LName} (Membership: {result.membershipNumber})
                      <Button variant="outline-success" size="sm" onClick={() => handleSendLinkRequest(result.id)}>
                        Send Link Request
                      </Button>
                    </li>
                  ))}
                </ul>
              ) : (
                <p>No search results.</p>
              )}
            </Card.Body>
          </Card>

          <Card className="shadow-sm">
            <Card.Header className="text-center">
              <h4>Pending Link Requests</h4>
            </Card.Header>
            <Card.Body>
              {pendingRequests && pendingRequests.length > 0 ? (
                <ul className="list-group">
                  {pendingRequests.map((request) => (
                    <li key={request.id} className="list-group-item d-flex justify-content-between align-items-center">
                      <div>
                        Request from: {request.FName} {request.LName} (Membership: {request.requesterMembershipNumber})
                      </div>
                      <div>
                        <Button variant="outline-success" size="sm" className="me-2" onClick={() => handleAcceptLinkRequest(request)}>
                          Accept
                        </Button>
                        <Button variant="outline-danger" size="sm" onClick={() => handleRejectLinkRequest(request.id)}>
                          Reject
                        </Button>
                      </div>
                    </li>
                  ))}
                </ul>
              ) : (
                <p>No pending link requests.</p>
              )}
            </Card.Body>
          </Card>
        </Tab>
      </Tabs>

      {/* Modal for Adding Under 18 Member */}
      <Modal show={showUnder18Modal} onHide={handleCloseUnder18Modal}>
        <Modal.Header closeButton>
          <Modal.Title>Add Under 18 Member</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form>
            <Form.Group controlId="FName">
              <Form.Label>First Name</Form.Label>
              <Form.Control
                type="text"
                name="FName"
                value={newUnder18Details.FName}
                onChange={handleUnder18InputChange}
              />
            </Form.Group>
            <Form.Group controlId="LName" className="mt-3">
              <Form.Label>Last Name</Form.Label>
              <Form.Control
                type="text"
                name="LName"
                value={newUnder18Details.LName}
                onChange={handleUnder18InputChange}
              />
            </Form.Group>
            <Form.Group controlId="membershipNumber" className="mt-3">
              <Form.Label>Membership Number</Form.Label>
              <Form.Control
                type="text"
                name="membershipNumber"
                value={newUnder18Details.membershipNumber}
                onChange={handleUnder18InputChange}
              />
            </Form.Group>
            <Form.Group controlId="membershipType" className="mt-3">
              <Form.Label>Membership Type</Form.Label>
              <Form.Select
                name="membershipType"
                value={newUnder18Details.membershipType}
                onChange={handleUnder18InputChange}
              >
                <option value="">Select Membership Type</option>
                <option value="Premium">Premium</option>
                <option value="Full">Full</option>
                <option value="Lite">Lite</option>
                <option value="under 16">Under 16</option>
              </Form.Select>
            </Form.Group>
            <Form.Group controlId="dob" className="mt-3">
              <Form.Label>Date of Birth</Form.Label>
              <Form.Control
                type="date"
                name="dob"
                value={newUnder18Details.dob}
                onChange={handleUnder18InputChange}
              />
            </Form.Group>
          </Form>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleCloseUnder18Modal}>
            Close
          </Button>
          <Button variant="primary" onClick={handleAddUnder18Member}>
            Save Under 18 Member
          </Button>
        </Modal.Footer>
      </Modal>
    </Container>
  );
}

export default ProfilePage;
