// src/pages/MatchPage.js
import React, { useState, useEffect } from 'react';
import { Container, Tabs, Tab, Card, Row, Col, Badge, Button, Form, Modal } from 'react-bootstrap';
import { auth } from '../firebase'; // Import Firebase auth only
import { onAuthStateChanged } from 'firebase/auth';
import moment from 'moment';
import { FaTrashAlt, FaCheckCircle, FaSave, FaTimes, FaEye } from 'react-icons/fa'; // Import icons for Apply, Save, Cancel, Delete, View
import '../styles/MatchPage.css'; // Import custom styles
import AddMatchModal from '../components/AddMatchModal'; // Import the modal component

// Import services
import {
  fetchMatches,
  addMatch,
  deleteMatch,
  fetchUserDetails,
  fetchApplicationsForUser,
  addApplication,
  deleteApplication,
  fetchApplicationsForMatch
} from '../services/firebaseServices';

function MatchPage() {
  const [matches, setMatches] = useState([]);
  const [activeTab, setActiveTab] = useState('open');
  const [activeCompetition, setActiveCompetition] = useState('Premier League');
  const [showAddMatchModal, setShowAddMatchModal] = useState(false);
  const [showApplicationsModal, setShowApplicationsModal] = useState(false);
  const [applicationsList, setApplicationsList] = useState([]); // To store applications for the admin view
  const [isAdmin, setIsAdmin] = useState(false);
  const [expandedMatch, setExpandedMatch] = useState(null);
  const [linkedMembers, setLinkedMembers] = useState({});
  const [selectedMembers, setSelectedMembers] = useState([]);
  const [userDetails, setUserDetails] = useState(null);
  const [applyForSelf, setApplyForSelf] = useState(false);
  const [applications, setApplications] = useState([]);

  useEffect(() => {
    const loadMatches = async () => {
      try {
        const matchesData = await fetchMatches();
        setMatches(matchesData);
      } catch (error) {
        console.error("Error fetching matches: ", error);
      }
    };
    loadMatches();
  }, []);

  useEffect(() => {
    const checkIfAdminAndLoadUserDetails = async (user) => {
      if (user) {
        try {
          const userData = await fetchUserDetails(user.uid);
          if (userData) {
            setUserDetails(userData);
            setIsAdmin(userData.role === 'admin');
  
            // Get linked member IDs, including the user's own ID
            const linkedMemberIDs = userData.linkedMemberID || [];
            const allUserIDs = [user.uid, ...linkedMemberIDs];
  
            // Fetch applications for user and linked members
            const userApplications = await fetchApplicationsForUser(allUserIDs);
            setApplications(userApplications);
          } else {
            setIsAdmin(false);
          }
        } catch (error) {
          console.error("Error fetching user role: ", error);
        }
      } else {
        setIsAdmin(false);
      }
    };
  
    const unsubscribe = onAuthStateChanged(auth, (user) => {
      checkIfAdminAndLoadUserDetails(user);
    });
  
    return () => unsubscribe();
  }, []);
  

  const getFilteredMatches = () => {
    const now = moment();
    return matches
      .filter((match) => {
        if (match.competitionType !== activeCompetition) return false;
  
        switch (activeTab) {
          case 'open':
            if (match.applicationOpenDate && match.applicationCloseDate) {
              const openDate = moment(match.applicationOpenDate.toDate());
              const closeDate = moment(match.applicationCloseDate.toDate());
              return openDate.isBefore(now) && closeDate.isAfter(now);
            }
            return false;
          case 'upcoming':
            return match.applicationOpenDate && moment(match.applicationOpenDate.toDate()).isAfter(now);
          case 'closed':
            return match.applicationCloseDate && moment(match.applicationCloseDate.toDate()).isBefore(now);
          default:
            return false;
        }
      })
      .sort((a, b) => {
        // Sort matches by matchDate in ascending order
        const aDate = a.matchDate ? a.matchDate.toDate() : new Date(8640000000000000); // Use a large date if matchDate is not available
        const bDate = b.matchDate ? b.matchDate.toDate() : new Date(8640000000000000);
        return aDate - bDate; // Sorting by earliest date first
      });
  };

  const getMatchCountByCompetitionAndStatus = (competition, status) => {
    const now = moment();
    return matches.filter((match) => {
      if (match.competitionType !== competition) return false;

      switch (status) {
        case 'open':
          if (match.applicationOpenDate && match.applicationCloseDate) {
            const openDate = moment(match.applicationOpenDate.toDate());
            const closeDate = moment(match.applicationCloseDate.toDate());
            return openDate.isBefore(now) && closeDate.isAfter(now);
          }
          return false;
        case 'upcoming':
          return match.applicationOpenDate && moment(match.applicationOpenDate.toDate()).isAfter(now);
        case 'closed':
          return match.applicationCloseDate && moment(match.applicationCloseDate.toDate()).isBefore(now);
        default:
          return false;
      }
    }).length;
  };

  const getCrestPath = (matchName) => {
    const formattedName = matchName.trim().split(' ').join('_');
    try {
      return require(`../images/Crests/${formattedName}.png`);
    } catch (error) {
      return require('../images/Crests/default.png');
    }
  };

  const handleAddMatch = async (newMatch) => {
    try {
      await addMatch(newMatch);
      setMatches((prevMatches) => [...prevMatches, newMatch]);
    } catch (error) {
      console.error("Error adding new match: ", error);
    }
  };

  const handleDeleteMatch = async (matchId) => {
    try {
      await deleteMatch(matchId);
      setMatches((prevMatches) => prevMatches.filter(match => match.id !== matchId));
    } catch (error) {
      console.error("Error deleting match: ", error);
    }
  };

  const handleApplyClick = async (matchId) => {
    setExpandedMatch(expandedMatch === matchId ? null : matchId);
  
    try {
      const user = auth.currentUser;
      if (user) {
        const userDoc = await fetchUserDetails(user.uid);
        if (userDoc) {
          const linkedMemberIDs = userDoc.linkedMemberID || [];
          const linkedMembersData = await Promise.all(
            linkedMemberIDs.map(async (linkedId) => {
              const linkedDoc = await fetchUserDetails(linkedId);
              return linkedDoc
                ? {
                    name: linkedDoc.FName + ' ' + linkedDoc.LName,
                    membershipNumber: linkedDoc.membershipNumber,
                    userId: linkedId, // Include linked member's user ID
                  }
                : { name: 'Unknown Member', membershipNumber: '', userId: '' };
            })
          );
          setLinkedMembers((prev) => ({ ...prev, [matchId]: linkedMembersData }));
  
          // Fetch applications for the match
          const matchApplications = await fetchApplicationsForMatch(matchId);
  
          // Determine if the current user has already applied
          const userFullName = `${userDoc.FName} ${userDoc.LName}`;
          const userHasApplied = matchApplications.some(
            (app) => app.userID === user.uid && app.memberName === userFullName
          );
  
          setApplyForSelf(userHasApplied);
  
          // Determine if linked members have already applied
          const appliedLinkedMembers = linkedMembersData
            .filter((member) =>
              matchApplications.some((app) => app.userID === member.userId && app.memberName === member.name)
            )
            .map((member) => member.name);
  
          setSelectedMembers(appliedLinkedMembers);
        }
      }
    } catch (error) {
      console.error("Error fetching linked members or applications: ", error);
    }
  };
  

  const handleSaveApplication = async (matchId) => {
    const user = auth.currentUser;
    if (user) {
      try {
        const userFullName = `${userDetails.FName} ${userDetails.LName}`;
  
        // Add or delete application for the user (self)
        const existingApplicationForUser = applications.find(
          (app) => app.matchID === matchId && app.userID === user.uid
        );
  
        if (applyForSelf && !existingApplicationForUser) {
          // If applying for self and no existing application, add it
          await addApplication({
            matchID: matchId,
            memberName: userFullName,
            memberNumber: userDetails.membershipNumber,
            userID: user.uid, // Save current user's ID
          });
        } else if (!applyForSelf && existingApplicationForUser) {
          // If not applying for self and there is an existing application, delete it
          await deleteApplication(existingApplicationForUser.id);
        }
  
        // Add or delete applications for linked members
        const updatedApplications = [...applications]; // Copy current applications to update state
  
        for (let member of linkedMembers[matchId] || []) {
          const existingApplicationForMember = applications.find(
            (app) => app.matchID === matchId && app.userID === member.userId
          );
  
          if (selectedMembers.includes(member.name) && !existingApplicationForMember) {
            // If applying for a linked member and no existing application, add it
            await addApplication({
              matchID: matchId,
              memberName: member.name,
              memberNumber: member.membershipNumber,
              userID: member.userId, // Save linked member's user ID
            });
  
            // Update local state to reflect the added application
            updatedApplications.push({
              matchID: matchId,
              memberName: member.name,
              memberNumber: member.membershipNumber,
              userID: member.userId,
            });
          } else if (!selectedMembers.includes(member.name) && existingApplicationForMember) {
            // If not applying for a linked member and there is an existing application, delete it
            await deleteApplication(existingApplicationForMember.id);
  
            // Update local state to remove the deleted application
            const index = updatedApplications.findIndex(
              (app) => app.matchID === matchId && app.userID === member.userId
            );
            if (index !== -1) {
              updatedApplications.splice(index, 1);
            }
          }
        }
  
        // Reset states after save operation is complete
        setExpandedMatch(null);
        setSelectedMembers([]);
        setApplyForSelf(false);
  
        // Update applications state to ensure UI reflects current application status
        setApplications(updatedApplications);
      } catch (error) {
        console.error("Error saving application: ", error);
      }
    }
  };
  

  const handleCancelApplication = () => {
    setExpandedMatch(null);
    setSelectedMembers([]);
    setApplyForSelf(false);
  };

  const handleMemberSelection = (member) => {
    if (selectedMembers.includes(member)) {
      setSelectedMembers(selectedMembers.filter((m) => m !== member));
    } else {
      setSelectedMembers([...selectedMembers, member]);
    }
  };

  const handleApplyForSelfChange = () => {
    setApplyForSelf(!applyForSelf);
  };

  const handleViewApplications = async (matchId) => {
    try {
      // Fetch the applications for the specified match
      const applications = await fetchApplicationsForMatch(matchId);
  
      // Get the match details to determine the match date
      const match = matches.find((m) => m.id === matchId);
      if (!match || !match.matchDate) {
        console.error("Match date not available for match:", matchId);
        return;
      }
  
      // Convert matchDate to a Moment.js date
      const matchDate = moment(match.matchDate.toDate());
  
      // Format applications with ticket type based on age on match date
      const formattedApplications = await Promise.all(applications.map(async (app) => {
        // Fetch user details for each applicant (userID might belong to current user or linked member)
        const userDetails = await fetchUserDetails(app.userID);
  
        if (!userDetails) {
          console.error("User details not found for userID:", app.userID);
          return { ...app, ticketType: 'Unknown', memberName: 'Unknown' };
        }
  
        // Calculate age on the match date
        const dob = userDetails.dob ? moment(userDetails.dob.toDate()) : null;
        const age = dob ? matchDate.diff(dob, 'years') : null;
        let ticketType;
  
        // Determine the ticket type based on the age on the match date
        if (age !== null) {
          if (age <= 16) {
            ticketType = 'U16';
          } else if (age >= 65) {
            ticketType = 'O65';
          } else if (age >= 17 && age <= 18) {
            ticketType = '17-18';
          } else if (age >= 18 && age <= 20) {
            ticketType = '18-20';
          } else {
            ticketType = 'Adult';
          }
        } else {
          ticketType = 'Unknown';
        }
  
        return {
          ...app,
          ticketType,
          memberName: `${userDetails.FName} ${userDetails.LName}`,
          memberNumber: userDetails.membershipNumber,
        };
      }));
  
      // Update state to show the applications in the modal
      setApplicationsList(formattedApplications);
      setShowApplicationsModal(true);
    } catch (error) {
      console.error("Error fetching applications for match: ", error);
    }
  };
  


  return (
    <Container className="mt-5">
      {isAdmin && (
        <div className="mb-3 d-flex justify-content-end">
          <Button variant="primary" onClick={() => setShowAddMatchModal(true)}>
            Add New Match
          </Button>
        </div>
      )}

      <div className='tabs-container mb-3'>
        <Tabs
          id="competition-tabs"
          activeKey={activeCompetition}
          onSelect={(k) => setActiveCompetition(k || 'Premier League')}
          className="mb-4 custom-tabs"
        >
          <Tab eventKey="Premier League" title={<span className="tab-title">Premier League ({getMatchCountByCompetitionAndStatus('Premier League', activeTab)})</span>} />
          <Tab eventKey="European Cup" title={<span className="tab-title">European Cup ({getMatchCountByCompetitionAndStatus('European Cup', activeTab)})</span>} />
          <Tab eventKey="Domestic Cups" title={<span className="tab-title">Domestic Cups ({getMatchCountByCompetitionAndStatus('Domestic Cups', activeTab)})</span>} />
        </Tabs>
      </div>
      
      <div className='tabs-container mb-3'>
        <Tabs
          id="match-status-tabs"
          activeKey={activeTab}
          onSelect={(k) => setActiveTab(k || 'open')}
          className="mb-3 custom-tabs"
        >
          <Tab eventKey="open" title={<span className="tab-title">Open ({getMatchCountByCompetitionAndStatus(activeCompetition, 'open')})</span>} />
          <Tab eventKey="upcoming" title={<span className="tab-title">Upcoming ({getMatchCountByCompetitionAndStatus(activeCompetition, 'upcoming')})</span>} />
          <Tab eventKey="closed" title={<span className="tab-title">Closed ({getMatchCountByCompetitionAndStatus(activeCompetition, 'closed')})</span>} />
        </Tabs>
      </div>

      <Row>
        {getFilteredMatches().map((match) => {
          const matchDateFormatted = match.matchDate ? moment(match.matchDate.toDate()).format('MMMM Do YYYY, h:mm a') : 'Date not available';
          const closeDateFormatted = match.applicationCloseDate ? moment(match.applicationCloseDate.toDate()).format('MMMM Do YYYY, h:mm a') : 'Date not available';
          const userHasApplied = applications.some((app) => app.matchID === match.id);
          const applicationOpenDateFormatted = match.applicationOpenDate ? moment(match.applicationOpenDate.toDate()).format('MMMM Do YYYY, h:mm a') : 'Date not available';

          return (
            <Col md={6} lg={4} key={match.id} className="mb-4">
              <Card className="h-100 shadow-sm custom-card">
                <Card.Header className="text-center custom-card-header">
                  {match.name}
                </Card.Header>
                <Card.Body className="custom-card-body">
                  <Row>
                    <Col xs={4} className="d-flex align-items-center">
                      <img
                        src={getCrestPath(match.name)}
                        alt="Club Crest"
                        className="img-fluid custom-crest-img"
                      />
                    </Col>
                    <Col xs={8}>
                      <Card.Text>
                        <strong>Match Date:</strong> {matchDateFormatted}
                      </Card.Text>
                      {activeTab === 'open' && (
                        <Card.Text>
                          <strong>Application Close Date:</strong> {closeDateFormatted}
                        </Card.Text>
                      )}
                      {activeTab === 'upcoming' && (
                        <Card.Text>
                          <strong>Application Open Date:</strong> {applicationOpenDateFormatted}
                        </Card.Text>
                      )}
                    </Col>
                  </Row>
                  <Row className="mt-3">
                    <Col className="d-flex justify-content-start align-items-center">
                      <Badge
                        pill
                        className={`status-badge ${
                          activeTab === 'open'
                            ? 'bg-success' // Green for Open
                            : activeTab === 'upcoming'
                            ? 'bg-info' // Blue for Upcoming
                            : 'bg-danger' // Red for Closed
                        }`}
                      >
                        {activeTab.toUpperCase()}
                      </Badge>
                      <Badge
                        pill
                        className={`competition-badge ms-2 ${
                          match.competitionType === 'Premier League'
                            ? 'bg-primary' // Blue for Premier League
                            : match.competitionType === 'European Cup'
                            ? 'bg-purple' // Purple for European Cup
                            : 'bg-orange' // Orange for Domestic Cups
                        }`}
                      >
                        {match.competitionType}
                      </Badge>
                      {userHasApplied && (
                        <Badge pill className="bg-secondary ms-2">
                          Applied
                        </Badge>
                      )}
                    </Col>
                  </Row>
                  {expandedMatch === match.id && (
                    <div className="mt-3">
                      <Form.Group>
                        {userDetails && (
                          <Form.Check
                            type="checkbox"
                            label={`Apply for ${userDetails.FName} ${userDetails.LName}`}
                            onChange={handleApplyForSelfChange}
                            checked={applyForSelf}
                          />
                        )}
                        {linkedMembers[match.id]?.map((member, index) => (
                          <Form.Check
                            key={index}
                            type="checkbox"
                            label={`Apply for ${member.name} (Membership: ${member.membershipNumber})`}
                            onChange={() => handleMemberSelection(member.name)}
                            checked={selectedMembers.includes(member.name)}
                          />
                        ))}
                      </Form.Group>
                    </div>
                  )}
                  <Row className="mt-3 d-flex justify-content-center">
                    <Col xs="auto">
                      {expandedMatch !== match.id ? (
                        activeTab === 'open' && userDetails?.role !== 'temp' && 
                        userDetails?.muscPaid === 'yes' && userDetails?.registered === 'yes' && (
                          <Button
                            variant="outline-success"
                            className="me-2"
                            onClick={() => handleApplyClick(match.id)}
                          >
                            <FaCheckCircle /> {userHasApplied ? "Edit" : "Apply"}
                          </Button>
                        )
                      ) : (
                        <>
                          <Button
                            variant="outline-primary"
                            className="me-2"
                            onClick={() => handleSaveApplication(match.id)}
                          >
                            <FaSave /> Save
                          </Button>
                          <Button variant="outline-secondary" onClick={handleCancelApplication}>
                            <FaTimes /> Cancel
                          </Button>
                        </>
                      )}
                      {isAdmin && (
                        <>
                          <Button
                            variant="outline-danger"
                            onClick={() => handleDeleteMatch(match.id)}
                          >
                            <FaTrashAlt /> Delete
                          </Button>                          
                        </>
                      )}
                    </Col>
                  </Row>
                </Card.Body>
              </Card>
            </Col>
          );
        })}
      </Row>

      {/* Add Match Modal */}
      <AddMatchModal
        show={showAddMatchModal}
        onHide={() => setShowAddMatchModal(false)}
        onAddMatch={handleAddMatch}
      />

      {/* Applications Modal */}
      <Modal show={showApplicationsModal} onHide={() => setShowApplicationsModal(false)}>
        <Modal.Header closeButton>
          <Modal.Title>Applications for Match</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <table className="table">
            <thead>
              <tr>
                <th>Membership Number</th>
                <th>Full Name</th>
                <th>Ticket Type</th>
              </tr>
            </thead>
            <tbody>
              {applicationsList.map((app, index) => (
                <tr key={index}>
                  <td>{app.memberNumber}</td>
                  <td>{app.memberName}</td>
                  <td>{app.ticketType}</td>
                </tr>
              ))}
            </tbody>
          </table>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={() => setShowApplicationsModal(false)}>
            Close
          </Button>
        </Modal.Footer>
      </Modal>
    </Container>
  );
}

export default MatchPage;
