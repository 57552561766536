// src/pages/LoginPage.js
import React, { useState } from 'react';
import { signInWithEmailAndPassword, sendPasswordResetEmail } from 'firebase/auth';
import { auth } from '../firebase';
import { useNavigate, Link } from 'react-router-dom';
import { Form, Button, Container, Row, Col, Alert, Modal } from 'react-bootstrap';

function LoginPage() {
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [error, setError] = useState('');
  const [resetEmail, setResetEmail] = useState('');
  const [showResetModal, setShowResetModal] = useState(false);
  const [resetError, setResetError] = useState('');
  const [resetMessage, setResetMessage] = useState('');
  const navigate = useNavigate();

  const handleLogin = async (e) => {
    e.preventDefault();
    setError(''); // Clear previous error
    try {
      await signInWithEmailAndPassword(auth, email, password);
      navigate('/');
    } catch (err) {
      setError('Failed to login. Please check your credentials.');
    }
  };

  // Function to handle password reset
  const handlePasswordReset = async (e) => {
    e.preventDefault();
    setResetError('');
    setResetMessage('');
    try {
      await sendPasswordResetEmail(auth, resetEmail);
      setResetMessage('Password reset email has been sent.');
    } catch (err) {
      setResetError('Failed to send password reset email. Please check the email address.');
    }
  };

  const openResetModal = () => setShowResetModal(true);
  const closeResetModal = () => {
    setShowResetModal(false);
    setResetEmail('');
    setResetError('');
    setResetMessage('');
  };

  return (
    <Container className="d-flex justify-content-center align-items-center min-vh-100">
      <Row className="w-100">
        <Col md={{ span: 8, offset: 2 }} lg={{ span: 6, offset: 3 }}>
          <div className="bg-white p-4 rounded shadow-sm">
            <h2 className="text-center mb-4" style={{ color: '#DA291C' }}>Login</h2>
            <Form onSubmit={handleLogin}>
              <Form.Group controlId="formEmail" className="mb-3">
                <Form.Label>Email</Form.Label>
                <Form.Control
                  type="email"
                  placeholder="Enter your email"
                  value={email}
                  onChange={(e) => setEmail(e.target.value)}
                  required
                />
              </Form.Group>

              <Form.Group controlId="formPassword" className="mb-3">
                <Form.Label>Password</Form.Label>
                <Form.Control
                  type="password"
                  placeholder="Enter your password"
                  value={password}
                  onChange={(e) => setPassword(e.target.value)}
                  required
                />
              </Form.Group>

              {error && (
                <Alert variant="danger" className="text-center">
                  {error}
                </Alert>
              )}

              <Button type='submit' className="w-100 mt-3 custom-primary-button">
                Login
              </Button>
            </Form>
            <div className="text-center mt-3">
              <Button variant="link" onClick={openResetModal} style={{ color: 'var(--bs-primary)' }}>
                Forgot Password?
              </Button>
            </div>
            <p className="text-center mt-4">
              Don't have an account? <Link to="/register" style={{ color: 'var(--bs-primary)' }}>Register here</Link>
            </p>
          </div>
        </Col>
      </Row>

      {/* Password Reset Modal */}
      <Modal show={showResetModal} onHide={closeResetModal}>
        <Modal.Header closeButton>
          <Modal.Title>Reset Password</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form onSubmit={handlePasswordReset}>
            <Form.Group controlId="formResetEmail" className="mb-3">
              <Form.Label>Enter your email address</Form.Label>
              <Form.Control
                type="email"
                placeholder="Enter your email"
                value={resetEmail}
                onChange={(e) => setResetEmail(e.target.value)}
                required
              />
            </Form.Group>

            {resetError && (
              <Alert variant="danger" className="text-center">
                {resetError}
              </Alert>
            )}

            {resetMessage && (
              <Alert variant="success" className="text-center">
                {resetMessage}
              </Alert>
            )}

            <Button type="submit" className="w-100 mt-3 custom-primary-button">
              Send Reset Email
            </Button>
          </Form>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={closeResetModal}>
            Close
          </Button>
        </Modal.Footer>
      </Modal>
    </Container>
  );
}

export default LoginPage;
